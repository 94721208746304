var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:"},[_c('icons',{attrs:{"name":"arrow-back"}}),_c('div',{staticClass:"company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap"},[_c('h6',{staticClass:"header"},[_vm._v("Verification: Insurance Providers")]),_c('div',{staticClass:"mr-2 tw-mt-5 lg:tw-mt-0 ",staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-text-field',{staticClass:"p-0",attrs:{"placeholder":"Search for company","solo":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":_vm.color}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v(" mdi-filter-variant ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mt-4 mx-1 table",attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"search":_vm.search,"item-key":"id","single-select":false,"height":"available","hide-default-footer":_vm.insuranceProviders.length <= 7,"items":_vm.insuranceProviders,"no-data-text":_vm.not_found,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.companyLogo",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Company Logo")])]},proxy:true},{key:"item.companyLogo",fn:function(ref){
var item = ref.item;
return [(item.companyLogo)?_c('img',{staticClass:"company-logo",attrs:{"src":item.companyLogo,"alt":"logo"}}):_c('div',{staticClass:"no-image",style:({ backgroundColor: _vm.color })},[_c('h6',{staticClass:"tw-flex tw-justify-center tw-items-center "},[_vm._v(" "+_vm._s(item.companyName[0])+" ")])])]}},{key:"header.companyName",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Company Name")])]},proxy:true},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.companyName))])]}},{key:"header.email",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Email")])]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style text-lowercase"},[_vm._v(_vm._s(item.companyEmail))])]}},{key:"header.phoneNumber",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Phone Number")])]},proxy:true},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.firstContactPhoneNumber))])]}},{key:"header.verification",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Verification")])]},proxy:true},{key:"item.verification",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_c('v-switch',{attrs:{"inset":"","color":"#004AAD"},on:{"change":function($event){return _vm.toggleVerify(item)}},model:{value:(item.companyVerified),callback:function ($$v) {_vm.$set(item, "companyVerified", $$v)},expression:"item.companyVerified"}})],1)]}},{key:"item.actions",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}])},[_c('v-list',[_c('v-list-item',{},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text",staticStyle:{"color":"black"}},[_c('img',{staticClass:"tw-mr-2 icon-size",attrs:{"alt":""}}),_vm._v(" View ")])],1)],1)],1)]},proxy:true}],null,true)})],1)]),_c('modal',{attrs:{"dialog":_vm.showModal,"title":_vm.modalTitle,"description":_vm.modalDesc,"icon":"oops","loading":_vm.modalLoading},on:{"close":_vm.handleModal,"verifyTransportCompany":_vm.verifyInsuranceProvider}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }